<template>
  <div>
    <div class="table-operator" style="margin-bottom: 15px">
      <a-button type="primary" icon="plus" @click="modalAddEditLoad()">添加</a-button>
    </div>

    <s-table
      ref="pageTable"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data="dataset"
      :pagination="pagination"
      :pageSize="10"
      :scroll="{}"
    >
      <a slot="name" slot-scope="text">{{ text }}</a>
      <span slot="onoffState" slot-scope="text, record">
        <a-switch
          checked-children="开"
          un-checked-children="关"
          :defaultChecked="Boolean(record.onoffState)"
          @change="handleUpdateOnoffState(record.id, $event)"
        />
      </span>
      <span slot="operation" slot-scope="text, record">
        <a @click="modalAddEditLoad(record)">编辑</a> |
        <a-popconfirm
          title="您确认要删除此条记录吗？"
          ok-text="确认"
          cancel-text="取消"
          @confirm="handleRemove(record)"
        >
          <a>删除</a>
        </a-popconfirm>
      </span>
    </s-table>

    <a-modal
      key="addEdit"
      title="新增/编辑"
      :width="600"
      :footer="null"
      :centered="true"
      :keyboard="false"
      :maskClosable="false"
      :visible="modalAddEditVisible"
      @cancel="modalAddEditCancel"
    >
      <a-form :form="modalAddEditForm" @submit="modalAddEditSubmit">
        <a-form-item
          label="起始地"
          :labelCol="{ lg: { span: 7 }, sm: { span: 6 } }"
          :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
        >
          <a-cascader
            name="fromGeoCode"
            placeholder="请选择起始地"
            v-decorator="['fromGeoCode', modalAddEditFormRules.fromGeoCode]"
            :options="geoCascaderList"
          />
        </a-form-item>

        <a-form-item
          label="首重"
          :labelCol="{ lg: { span: 7 }, sm: { span: 6 } }"
          :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
        >
          <a-input-number
            name="firstWeight"
            :min="0"
            :step="0.01"
            v-decorator="['firstWeight', modalAddEditFormRules.firstWeight]"
          />
          <a-button disabled class="percentage"> KG</a-button>
        </a-form-item>

        <a-form-item
          label="提货最低收费"
          :labelCol="{ lg: { span: 7 }, sm: { span: 6 } }"
          :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
        >
          <a-input-number
            name="pickupPrice"
            :min="0"
            :step="0.01"
            v-decorator="['pickupPrice', modalAddEditFormRules.pickupPrice]"
          />
          <a-button disabled class="percentage"> 元</a-button>
        </a-form-item>

        <a-form-item
          label="一级转运价格"
          :labelCol="{ lg: { span: 7 }, sm: { span: 6 } }"
          :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
        >
          <a-input-number
            name="firstTransportPrice"
            :min="0"
            :step="0.01"
            v-decorator="['firstTransportPrice', modalAddEditFormRules.firstTransportPrice]"
          />
          <a-button disabled class="percentage"> 元</a-button>
        </a-form-item>

        <a-form-item
          label="二级转运价格"
          :labelCol="{ lg: { span: 7 }, sm: { span: 6 } }"
          :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
        >
          <a-input-number
            name="secondTransportPrice"
            :min="0"
            :step="0.01"
            v-decorator="['secondTransportPrice', modalAddEditFormRules.secondTransportPrice]"
          />
          <a-button disabled class="percentage"> 元</a-button>
        </a-form-item>

        <a-form-item
          label="派件最低收费"
          :labelCol="{ lg: { span: 7 }, sm: { span: 6 } }"
          :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
        >
          <a-input-number
            name="dispatchPrice"
            :min="0"
            :step="0.01"
            v-decorator="['dispatchPrice', modalAddEditFormRules.dispatchPrice]"
          />
          <a-button disabled class="percentage"> 元</a-button>
        </a-form-item>

        <a-form-item
          label="开启状态"
          :labelCol="{ lg: { span: 7 }, sm: { span: 6 } }"
          :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
        >
          <a-switch
            name="onoffState"
            :active-value="1"
            :inactive-value="0"
            checked-children="开"
            un-checked-children="关"
            v-decorator="['onoffState', modalAddEditFormRules.onoffState]"
          />
        </a-form-item>

        <a-form-item class="form-submit">
          <a-input name="id" type="hidden" style="display: none" v-decorator="['id', modalAddEditFormRules.id]" />
          <a-button style="margin-right: 15px" @click="modalAddEditCancel">取消</a-button>
          <a-button type="primary" htmlType="submit">提交</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { STable } from '@/components'
import { extChargeList, extChargeInsert, extChargeUpdate, extChargeRemove, geoCascaderList } from '@/api/quotation'

const chargeType = 1;
const columns = [
  {
    key: 'fromCityName',
    dataIndex: 'fromCityName',
    title: '起始地',
    align: 'center',
  },
  {
    key: 'firstWeight',
    dataIndex: 'firstWeight',
    align: 'center',
    title: '首重',
  },
  {
    key: 'pickupPrice',
    dataIndex: 'pickupPrice',
    align: 'center',
    title: '提货最低收费',
  },
  {
    key: 'firstTransportPrice',
    dataIndex: 'firstTransportPrice',
    align: 'center',
    title: '一级转运价格',
  },
  {
    key: 'secondTransportPrice',
    dataIndex: 'secondTransportPrice',
    align: 'center',
    title: '二级转运价格',
  },
  {
    key: 'dispatchPrice',
    dataIndex: 'dispatchPrice',
    align: 'center',
    title: '派件最低收费',
  },
  {
    key: 'updateDate',
    dataIndex: 'updateDate',
    align: 'center',
    title: '更新时间',
  },
  {
    key: 'onoffState',
    dataIndex: 'onoffState',
    align: 'center',
    title: '开启状态',
    scopedSlots: {
      customRender: 'onoffState',
    },
  },
  {
    key: 'operation',
    align: 'center',
    dataIndex: 'operation',
    title: '操作',
    scopedSlots: {
      customRender: 'operation',
    },
  },
]
const modalAddEditFormRules = {
  id: { initialValue: null },
  fromGeoCode: { rules: [{ required: true, message: '请选择起始地' }] },
  toGeoCode: { rules: [{ required: true, message: '请选择目的地' }] },
  firstWeight: { rules: [{ required: true, message: '请输入首重' }] },
  pickupPrice: { rules: [{ required: true, message: '请输入提货最低收费' }] },
  firstTransportPrice: { rules: [{ required: true, message: '请输入一级转运价格' }] },
  secondTransportPrice: { rules: [{ required: true, message: '请输入二级转运价格' }] },  
  dispatchPrice: { rules: [{ required: true, message: '请输入派件最低收费' }] },
  onoffState: { initialValue: true, valuePropName: 'checked' },
}

export default {
  name: 'TableList',
  components: {
    STable,
  },
  data() {
    return {
      columns: columns,
      dataset: (parameter) => {
        return extChargeList(Object.assign(parameter, this.queryParam))
          .then((res) => {
            if (0 == res.code) {
              return res.result
            }
          })
          .catch((e) => {
            console.info(e)
            this.$message.error('数据加载失败，请稍后重试')
          })
      },
      pagination: { pageSizeOptions: ['10', '20', '50'] },
      queryParam: { chargeType: 1 },
      modalAddEditVisible: false,
      modalAddEditForm: this.$form.createForm(this),
      modalAddEditFormRules: modalAddEditFormRules,
      geoCascaderList: [],
    }
  },
  mounted() {
    const parameter = {}
    geoCascaderList(parameter)
      .then((res) => {
        if (0 == res.code) {
          this.geoCascaderList = res.result
        }
      })
      .catch((e) => {
        console.info(e)
      })
  },
  methods: {
    modalAddEditLoad(record = null) {
      this.modalAddEditForm.resetFields()
      this.modalAddEditVisible = true
      if (record) {
        this.$nextTick(() => {
          this.modalAddEditForm.setFieldsValue({
            id: record.id,
            fromGeoCode: [record.fromProvinceCode, record.fromCityCode],
            firstWeight: record.firstWeight,
            pickupPrice: record.pickupPrice,
            firstTransportPrice: record.firstTransportPrice,
            secondTransportPrice:record.secondTransportPrice,
            dispatchPrice: record.dispatchPrice,
            onoffState: Boolean(record.onoffState),
          })
        })
      }
    },
    modalAddEditCancel() {
      this.modalAddEditVisible = false
    },
    modalAddEditSubmit(e) {
      e.preventDefault()
      let that = this
      this.modalAddEditForm.validateFields((err, values) => {
        if (!err) {
          values.fromProvinceCode = values.fromGeoCode[0]
          values.fromCityCode = values.fromGeoCode[1]
          values.onoffState = Number(values.onoffState)
          delete values.fromGeoCode
          if (values.id) {
            that.handleUpdate(values)
          } else {
            that.handleInsert(values)
          }
        }
      })
    },
    handleInsert(parameter) {
      parameter.chargeType = chargeType
      extChargeInsert(parameter)
        .then((res) => {
          if (0 == res.code) {
            this.$message.success('保存成功')
            this.modalAddEditVisible = false
            this.$refs.pageTable.refresh(true)
          } else {
            this.$message.error('保存失败')
          }
        })
        .catch((e) => {
          console.info(e)
          this.$message.error('保存失败，请稍后重试')
        })
    },
    handleUpdate(parameter) {
      extChargeUpdate(parameter)
        .then((res) => {
          if (0 == res.code) {
            this.$message.success('保存成功')
            this.modalAddEditVisible = false
            this.$refs.pageTable.refresh(true)
          } else {
            this.$message.error('保存失败')
          }
        })
        .catch((e) => {
          console.info(e)
          this.$message.error('保存失败，请稍后重试')
        })
    },
    handleUpdateOnoffState(id, onoffState) {
      const parameter = { id: id, onoffState: Number(onoffState) }
      this.handleUpdate(parameter)
    },
    handleRemove(record) {
      extChargeRemove(record)
        .then((res) => {
          if (0 == res.code) {
            this.$message.success('删除成功')
            this.$refs.pageTable.refresh(true)
          } else {
            this.$message.error('删除失败')
          }
        })
        .catch((e) => {
          console.info(e)
          this.$message.error('删除失败，请稍后重试')
        })
    },
  },
}
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100%;
}
.ant-form{
  /deep/ .ant-form-item-children{
    display: flex;
    .ant-input-number{
      border-radius: 5px 0 0 5px;
    }
    .percentage{
      border-radius:0 5px 5px 0;
      border-left: 0;
    }
  }
}
/deep/ .ant-table-tbody{
  background-color: #fff;
}
/deep/ .ant-form-item-label{
  text-align: left !important;
}
/deep/ .ant-table-pagination {
 > li{
  background-color: #fff !important;
  min-width: 35px !important;
  height: 35px !important;
  line-height: 35px !important;
  border-radius: 4px;
  box-sizing: border-box;
  >div{
    margin: 0 0;
    >div{
      border: none;
    }
   }
  }
  .ant-pagination-options{
   border:1px solid #d9d9d9;
  .ant-select-selection--single {
    height: 32px;
    .ant-select-selection__rendered{
      line-height: 35px;
    }
  }
  }
  .ant-pagination-item-active{
    border-color: #1890ff !important;
  }
}
/deep/ .ant-form-item-control-wrapper{
  width: 70.8%;
}
/deep/  .form-submit {
    margin-bottom: 0;
  .ant-form-item-control-wrapper{
    width: 100% !important;
    text-align: right;
  }
  .ant-form-item-children {
    justify-content: flex-end;
  }
}
</style>